//
//
//
//
//
//
//
//

import { computed, useFetch,ref, useContext, onMounted } from "@nuxtjs/composition-api";
import { useContent } from "~/composables";
import { determineHomePageIconsIdentifier } from "~/utils/HomePageIdentifiers";

export default ({
  name: "HomePageIconsBlockCard",
  setup() {
    // Mock data for icons
    
    const {loadBlocks} = useContent();
    const blocks = ref(null);
    const {app} = useContext();
    // useFetch(async () => {
      
    // });


    // onMounted( async () => {
    //   const get_identifier = determineHomePageIconsIdentifier(app.i18n.locale);

    //  const response = await loadBlocks({identifiers: [get_identifier]});
    //  const textarea = document.createElement("textarea");
    //  console.log("Yes mounted in HomePageIconsBlockCard",response);
    //  if(response && response.length > 0){
    //   textarea.innerHTML = response[0]?.content || '';
    //   blocks.value = textarea.value;
    //  }
    //   console.log("Yes mounted in HomePageIconsBlockCard");
    // })

    

    return {
      blocks:computed(() => blocks.value),
    };
  },
});
