//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import {
    defineComponent,
    ref,
    useContext,
    onMounted,
    watch,
    toRaw,
    toRef,
  } from "@nuxtjs/composition-api";
import Loader from "../Loader.vue";
  
  import VueSlickCarousel from "vue-slick-carousel";
  export default defineComponent({
    name: "WorldBrandSlider",
    components: {
      VueSlickCarousel,
      Loader
    },
    setup() {
      const { app } = useContext();


      const slider_loader = ref(true);
      const sliderInfo = ref({
        GetBrands:[]
      });
      const carousel = ref(null);
      const iconTextItems = [
        {
          icon: "https://bq247.o2usd.net/media/wysiwyg/Online_Shopping-Shopping-Smartphone-Shopping_Bag-Commerce.png",
          title: "Order online &",
          text: "Collect in store",
        },
        {
          icon: "https://bq247.o2usd.net/media/wysiwyg/return.png",
          title: "Return &",
          text: "Exchange policy",
        },
        {
          icon: "https://bq247.o2usd.net/media/wysiwyg/track.png",
          title: "Delivery &",
          text: "Installation",
        },
      ];
      function onInitCarousel() {
        carousel?.value?.play();
      }
  
      function showNext() {
        carousel.value.next();
      }
      const showPrevious = () => {
        carousel.value.prev();
      };
  
      const sliderSettings = {
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        dots: false,
        responsive: [
      {
        breakpoint: 812,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,

        }
       
      },
      {
        breakpoint: 612,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

        }
      },
    ]
      };

      onMounted( async () => {
      const read_slider = await app.$vsf.$magento.api.customQuery({
        query: `
        query {
          GetBrands{
            storeids
            image
            name
            showcap
            alt
          }
        }`,
        customHeaders:{
          'store':app.i18n.locale
        }
      });

      


      sliderInfo.value = read_slider.data;
      slider_loader.value = false

    });

    watch((sliderInfo), () => {
      if(sliderInfo.value.GetBrands.length > 0){
        slider_loader.value = false
      }
    })
    
      return {
        onInitCarousel,
        carousel,
        showNext,
        showPrevious,
        sliderSettings,
        iconTextItems,
        sliderInfo,
        slider_loader
      };
    },
  });
  