//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useContext, useRouter, watch,computed } from '@nuxtjs/composition-api';
import { ref } from 'vue';
import { useWishlist } from '~/composables';
import useCompare from '~/composables/useCompare';
import {storeWiseCurrency} from '~/utils/HomePageIdentifiers';

export default {
  name: 'ProductCard',
  props: {
    linkUrl: String|null,
    title: String|null,
    bgImage: String,
    percentage: Number,
    id: Number|String,
    price: String|Number,
    wishHandler: Function,
    wish: Boolean,
    regularPrice: String|Number,
    finalPrice: String|Number,
    removeCompare:String|Number,
    regularPriceCurrency: {
      type: String|Number,
      default: 'SAR',
    },
    finalPriceCurrency: {
      type: String|Number,
      default: 'SAR',
    },
    product: Object,
  },
  setup(props, { emit }) {
    const compare = useCompare();
    const router = useRouter();
    const {app} = useContext();
    const { addOrRemoveItem, isInWishlist } = useWishlist();


    const navigateToLink = () => {
      console.log("LinkUrl ---->",props.linkUrl);
      if(props.linkUrl){
        router.push({path : '/' + (app.i18n.locale == app.i18n.defaultLocale ? '' : app.i18n.locale + '/') + (props.linkUrl)})
      }
    };

   

    const wishHandlerFired = (e) => {
      e.stopPropagation();
      if(props.product){
        addOrRemoveItem({ product: props.product });

      }
    };

    const compareHandlerFired = (e) => {
      e.stopPropagation();
      if(props.id){
        compare.addProductToCompareList(props.id);
      }
      
    };

  
    return {
      navigateToLink,
      wishHandlerFired,
      compareHandlerFired,
      isInWishlist: props.product ? computed(() => isInWishlist({ product: props.product })) : false,
      currency:app.$vsf.$magento.config.state.getCurrency() ||  storeWiseCurrency(app.i18n.locale),

    };
  },
};
