import { render, staticRenderFns } from "./ProductSlider.vue?vue&type=template&id=19e5bda1&scoped=true&"
import script from "./ProductSlider.vue?vue&type=script&lang=ts&"
export * from "./ProductSlider.vue?vue&type=script&lang=ts&"
import style0 from "./ProductSlider.vue?vue&type=style&index=0&id=19e5bda1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19e5bda1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCard: require('/var/www/domains/bedquarter.com/public_html/components/Cards/ProductCard.vue').default,Loader: require('/var/www/domains/bedquarter.com/public_html/components/Loader.vue').default})
