import { render, staticRenderFns } from "./WorldBrandSlider.vue?vue&type=template&id=8819aade&scoped=true&"
import script from "./WorldBrandSlider.vue?vue&type=script&lang=js&"
export * from "./WorldBrandSlider.vue?vue&type=script&lang=js&"
import style0 from "./WorldBrandSlider.vue?vue&type=style&index=0&id=8819aade&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8819aade",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/var/www/domains/bedquarter.com/public_html/components/Loader.vue').default})
