











































  import {
    defineComponent, PropType, ref,
  } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';
  

  
  export default defineComponent({
    name: 'ContactSection',
    setup() {

      const {config} = useConfig();
     
  
      return {
        config:(config as any)
      };
    },
  });
  