//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  defineComponent,
  ref,
  useContext,
  onMounted,
  useFetch,
  toRaw,
  toRef,
  watch,
} from "@nuxtjs/composition-api";

import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import MainSliderCard from "./MainSliderCard.vue";
import {main_slider_ids} from "~/utils/HomePageIdentifiers";
import Loader from "../Loader.vue";
export default defineComponent({
  name: "MainSlider",
  components: {
    VueSlickCarousel,
    MainSliderCard,
    Loader
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { app } = useContext();

    const { isDesktop } = app.$device;

    const carousel = ref(null);
    const slider_loader = ref(true);
    const sliderInfo = ref({
      MagicSlider:{
        media_gallery: []
      }
    });
   
    function onInitCarousel() {
      carousel?.value?.play();
    }

    function showNext() {
      if(app.i18n.localeProperties.file == 'ar.js'){
        carousel.value.prev();
      } else {
        carousel.value.next();
      }
    }
    const showPrevious = () => {
      if(app.i18n.localeProperties.file == 'ar.js'){
        carousel.value.next();
      } else {
        carousel.value.prev();
      }
    };
   

    const sliderSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };

    onMounted(() => {
      
    });


    onMounted( async () => {
      const read_slider = await app.$vsf.$magento.api.customQuery({
        query: `
        query {
        MagicSlider(id: "${main_slider_ids(app.i18n.locale)}") {
            title
            media_gallery {
              disabled
              bnType
              bnTitle
              short
              bnImg2
              btntitle
              bnUrl
              bnImg
              bnContent
              newtab
            }
          }
        }`,

        customHeaders:{
         'store': app.i18n.locale,
        }

      });

      


      sliderInfo.value = read_slider.data;
      slider_loader.value = false

    });

    watch((sliderInfo), () => {
      if(sliderInfo.value.MagicSlider.media_gallery.length > 0){
        slider_loader.value = false

       setTimeout(() => {
        const slickDots = document.querySelector(
        ".main-slider-component .slick-dots"
        );
        if (slickDots) {
          slickDots.classList.add("custom-main-slider-dot-class"); // Add your custom class here
        }
       }, 100);
      }
    })

    return {
      onInitCarousel,
      carousel,
      sliderInfo,
      showNext,
      showPrevious,
      sliderSettings,
      slider_loader
    };
  },
});
