//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'MainSliderCard',
  props: {
    bannerImage: {
      type: String|null,
      required: true
    },
    title: {
      type: String|null,
      required: true
    },
    subtitle: {
      type: String|null,
      required: true
    },
    linkUrl: {
      type:String|null,
      required: true
    },
    btnTitle: {
      type: String|null,
      required: true
    },
    isReverse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentBannerImage: require('../../assets/images/main-slider.svg') // Set the default fallback image initially
    };
  },
  mounted() {
    this.loadBannerImage();
  },
  methods: {
    loadBannerImage() {
      // Check if `bannerImage` is provided
      if (!this.bannerImage) {
        this.currentBannerImage = require('../../assets/images/main-slider.svg');
        return;
      }

      // Create an Image object to check if the image loads successfully
      const img = new Image();
      img.src = this.bannerImage;

      img.onload = () => {
        // If image loads successfully, set it as the background
        this.currentBannerImage = this.bannerImage;
      };
      img.onerror = () => {
        // If image fails to load, use the fallback image
        this.currentBannerImage = require('../../assets/images/main-slider.svg');
      };
    },
    navigateToLink() {
      window.location.href = this.linkUrl ? this.linkUrl : 'https://sfmage.o2usd.net/ksa_en/mattress.html';
    }
  }
};
