//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ShopByCategoryBlockCards',
  props: {

  title: {
    type: String,
    required: false,
    default:''
  },
    
  categoryCard: {
  type: Array,
  required: false,
  default: () => [],
  validator(value) {
    return value.every(item => 
      item.hasOwnProperty('bgimage') && typeof item.iconImage === 'string' &&
      item.hasOwnProperty('title') && typeof item.paragraph === 'string' &&
      item.hasOwnProperty('url') && typeof item.paragraph === 'string'

    );
  }
}
  },
  methods: {
    navigateToLink(url) {
      

      this.$emit('navigateToLinkFromHome', url)
      
    }
  }
};
