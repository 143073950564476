import type { MetaInfo } from 'vue-meta';

export const getMetaInfo = (page: any, isNoIndex: boolean = false): MetaInfo => {
  console.log("page meta info --->",page)
  if (!page) {
    return null;
  }

  const seoTags: MetaInfo = {
    meta: [],
  };

  if (page?.meta_title || page?.title || page?.name) {
    seoTags.title = page?.title || page?.meta_title ||  page?.name;
  }
  if (page?.meta_description) {
    seoTags.meta.push({
      hid: 'description',
      name: 'description',
      content: page.meta_description,
    });
  }
  if (page?.meta_keyword || page?.meta_keywords) {
    seoTags.meta.push({
      hid: 'keywords',
      name: 'keywords',
      content: page?.meta_keyword || page?.meta_keywords,
    });
  }



  if (page?.name) {
    seoTags.meta.push({
      property: 'og:title',
      content: page?.name ,
    });
  } else {
    seoTags.meta.push({
      property: 'og:title',
      content:  page?.meta_title || page?.title ,
    });
  }

  if (page?.meta_description) {
    seoTags.meta.push({
      property: 'og:description',
      content: page?.meta_description,
    });
  }



// Open Graph Keywords
if (page?.meta_keyword || page?.meta_keywords) {
  seoTags.meta.push({
    property: 'og:keywords',
    content: page?.meta_keyword || page?.meta_keywords,
  });
}



  // Open Graph Image
  if (page?.image?.url) {
    seoTags.meta.push({
      property: 'og:image',
      content: page.image?.url,
      alt: page?.meta_title,
    });
  } else {
    seoTags.meta.push({
      property: 'og:image',
      content: require('@/assets/images/logo.svg'),
      alt: page?.meta_title,
    });
  }


  if (isNoIndex) {
    seoTags.meta.push({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }

  return seoTags;
};
