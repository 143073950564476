//
//
//
//
//
//
//

import { computed, useFetch, ref, useContext } from "@nuxtjs/composition-api";
import { useContent } from "~/composables";
import { homePageCTABlockIdentifier } from "~/utils/HomePageIdentifiers";

export default {
  name: "HomePageCTA",
  setup() {
    // Mock data for icons

    const { loadBlocks } = useContent();
    const blocks = ref(null);
    const {app} = useContext();

    useFetch(async () => {
      const response = await loadBlocks({ identifiers: [homePageCTABlockIdentifier(app.i18n.locale)] });
      const textarea = document.createElement("textarea");
      console.log("Reading Block CTA", response);
      if (response.length > 0) {
        textarea.innerHTML = response[0]?.content;
        blocks.value = textarea.value;
      }
    });

    

    return {
      blocks: computed(() => blocks.value),
    };
  },
};
