import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=2f6c29f3&scoped=true&"
import script from "./Home.vue?vue&type=script&lang=ts&"
export * from "./Home.vue?vue&type=script&lang=ts&"
import style0 from "./Home.vue?vue&type=style&index=0&id=2f6c29f3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f6c29f3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainSlider: require('/var/www/domains/bedquarter.com/public_html/components/MainSlider/MainSlider.vue').default,HomePageIconsBlockCard: require('/var/www/domains/bedquarter.com/public_html/components/Cards/HomePageIconsBlockCard.vue').default,ShopByCategoryBlockCards: require('/var/www/domains/bedquarter.com/public_html/components/Cards/ShopByCategoryBlockCards.vue').default,HomePageCTA: require('/var/www/domains/bedquarter.com/public_html/components/Cards/HomePageCTA.vue').default,WorldBrandSlider: require('/var/www/domains/bedquarter.com/public_html/components/WorldBrandSlider/WorldBrandSlider.vue').default,ProductSlider: require('/var/www/domains/bedquarter.com/public_html/components/ProductSlider/ProductSlider.vue').default,LatestProductSlider: require('/var/www/domains/bedquarter.com/public_html/components/LatestProductSlider/LatestProductSlider.vue').default,ContactSection: require('/var/www/domains/bedquarter.com/public_html/components/ContactSectionUI/ContactSection.vue').default})
