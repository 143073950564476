import { render, staticRenderFns } from "./LatestProductSlider.vue?vue&type=template&id=71b6764a&scoped=true&"
import script from "./LatestProductSlider.vue?vue&type=script&lang=ts&"
export * from "./LatestProductSlider.vue?vue&type=script&lang=ts&"
import style0 from "./LatestProductSlider.vue?vue&type=style&index=0&id=71b6764a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71b6764a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCard: require('/var/www/domains/bedquarter.com/public_html/components/Cards/ProductCard.vue').default,Loader: require('/var/www/domains/bedquarter.com/public_html/components/Loader.vue').default})
