


















































































import {
  defineComponent,
  ref,
  useContext,
  onMounted,
  useFetch,
  watch,
} from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { SfBanner, SfBannerGrid } from '@storefront-ui/vue';
import { CmsPage } from '~/modules/GraphQL/types';
import HeroSection from '~/components/HeroSection.vue';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useCategory, useConfig, useContent } from '~/composables';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import MainSlider from '~/components/MainSlider/MainSlider.vue';
import HomePageIconsBlockCard from '~/components/Cards/HomePageIconsBlockCard.vue';
import ShopByCategoryBlockCards from '~/components/Cards/ShopByCategoryBlockCards.vue';
import WorldBrandSlider from '~/components/WorldBrandSlider/WorldBrandSlider.vue';
import ProductSlider from '~/components/ProductSlider/ProductSlider.vue';
import LatestProductSlider from '~/components/LatestProductSlider/LatestProductSlider.vue';
import ContactSection from '~/components/ContactSectionUI/ContactSection.vue';
import HomePageCTA from '~/components/Cards/HomePageCTA.vue';
import { homePageIdentifier } from '~/utils/HomePageIdentifiers';
export default defineComponent({
  name: 'HomePage',
  components: {
    MainSlider,
    HeroSection,
    HomePageIconsBlockCard,
    ShopByCategoryBlockCards,
    LatestProductSlider,
    WorldBrandSlider,
    HomePageCTA,
    LazyHydrate,
    ContactSection,
    LoadWhenVisible,
    ProductSlider,
    SfBanner,
    SfBannerGrid,
    CallToAction: () => import(/* webpackPrefetch: true */ '~/components/CallToAction.vue'),
    InstagramFeed: () => import(/* webpackPrefetch: true */ '~/components/InstagramFeed.vue'),
    MobileStoreBanner: () => import(/* webpackPrefetch: true */ '~/components/MobileStoreBanner.vue'),
    NewProducts: () => import(/* webpackPrefetch: true */ '~/components/NewProducts.vue'),
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { addTags } = useCache();
    const { loadPage } = useContent();
    const { app } = useContext();
    const year = new Date().getFullYear();
    const { isDesktop } = app.$device;
    const { categories: categoryList } =
    useCategory();
    const {config} = useConfig() as any;
    const page = ref<CmsPage | null>(null);
    const hero = ref({
      title: app.i18n.t('Colorful summer dresses are already in store'),
      subtitle: app.i18n.t('SUMMER COLLECTION {year}', { year }),
      buttonText: app.i18n.t('Learn more'),
      imageSrc: '/homepage/bannerB.webp',
      imageWidth: isDesktop ? 1240 : 328,
      imageHeight: isDesktop ? 400 : 224,
      imageConfig: {
        fit: 'cover',
        format: 'webp',
      },
      link: '/women.html',
    });
    const banners = ref([
      {
        slot: 'banner-A',
        subtitle: app.i18n.t('Dresses'),
        title: app.i18n.t('Cocktail & Party'),
        description: app.i18n.t(
          'Find stunning women\'s cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.',
        ),
        buttonText: app.i18n.t('Shop now'),
        image: {
          mobile:
            '/homepage/bannerB.webp',
          desktop:
            '/homepage/bannerF.webp',
        },
        imageConfig: {
          fit: 'cover',
          width: isDesktop ? 332 : 328,
          height: isDesktop ? 840 : 343,
          format: 'webp',
        },
        class: 'sf-banner--slim desktop-only',
        link: '/women/women-clothing-skirts',
      },
      {
        slot: 'banner-B',
        subtitle: app.i18n.t('Dresses'),
        title: app.i18n.t('Linen Dresses'),
        description: app.i18n.t(
          'Find stunning women\'s cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.',
        ),
        buttonText: app.i18n.t('Shop now'),
        image: '/homepage/bannerE.webp',
        imageConfig: {
          fit: 'cover',
          width: isDesktop ? 496 : 328,
          height: isDesktop ? 840 : 343,
          format: 'webp',
        },
        class: 'sf-banner--slim banner-central desktop-only',
        link: '/women/women-clothing-dresses',
      },
      {
        slot: 'banner-C',
        subtitle: app.i18n.t('T-Shirts'),
        title: app.i18n.t('The Office Life'),
        image: '/homepage/bannerC.webp',
        imageConfig: {
          fit: 'cover',
          width: isDesktop ? 332 : 328,
          height: isDesktop ? 400 : 343,
          format: 'webp',
        },
        class: 'sf-banner--slim banner__tshirt',
        link: '/women/women-clothing-shirts',
      },
      {
        slot: 'banner-D',
        subtitle: app.i18n.t('Summer Sandals'),
        title: app.i18n.t('Eco Sandals'),
        image: '/homepage/bannerG.webp',
        imageConfig: {
          fit: 'cover',
          width: isDesktop ? 332 : 328,
          height: isDesktop ? 400 : 343,
          format: 'webp',
        },
        class: 'sf-banner--slim',
        link: '/women/women-shoes-sandals',
      },
    ]);
    const callToAction = ref({
      title: app.i18n.t('Subscribe to Newsletters'),
      description: app.i18n.t('Be aware of upcoming sales and events. Receive gifts and special offers!'),
      buttonText: app.i18n.t('Subscribe'),
      imageSrc: '/homepage/newsletter.webp',
      imageWidth: isDesktop ? 1240 : 400,
      imageHeight: isDesktop ? 202 : 200,
      imageConfig: {
        fit: 'cover',
        format: 'webp',
      },
    });

    const categoryCard = ref([])

    useFetch(async () => {
      page.value = await loadPage({ identifier: homePageIdentifier(app.i18n.locale) });
    });

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
      if(categoryList.value){
        categoryCard.value = categoryList.value
  .filter((category) => category.include_in_menu)
  .slice(0, 4)?.map((category) => {
    return {
     
        title: app.i18n.t(category.name),
        bgimage: category.image,
        url: '/' + category.url_path + category.url_suffix
  
    }
  });

      }
    });

    const iconsInfo = [
      {
        paragraph: app.i18n.t('Perfect setup with our delivery and installation'),
        iconImage: require('~/assets/images/delivery-box.svg')
      },
      {
        paragraph: app.i18n.t('Hassle-free returns for customer satisfaction'),
        iconImage: require('~/assets/images/product-return.svg')
      },
      {
        paragraph: app.i18n.t('Convenient online ordering and in-store pickup'),
        iconImage: require('~/assets/images/shopping-cart.svg')
      },
      {
        paragraph: app.i18n.t('NASA-patented comfort and durability'),
        iconImage: require('~/assets/images/brand-nasa.svg')
      }
    ]

  

    const worldbrandslider = [
      require('~/assets/images/wbs1.svg'),
      require('~/assets/images/wbs2.svg'),
      require('~/assets/images/wbs3.svg'),
      require('~/assets/images/wbs4.svg'),
      require('~/assets/images/wbs5.svg'),
      require('~/assets/images/wbs6.svg'),
      require('~/assets/images/wbs7.svg'),
      require('~/assets/images/wbs8.svg'),
      require('~/assets/images/wbs9.svg'),
    ]

    watch((categoryList), () => {
      if(categoryList.value){
        categoryCard.value = categoryList.value
  .filter((category) => category.include_in_menu)
  .slice(0, 4)?.map((category) => {
    return {
     
        title: app.i18n.t(category.name),
        bgimage: validateThumbnailImageUrl(category.image),
        url: '/' + category.url_path + category.url_suffix
  
    }
  });

      }
    })

    const navigateToLink = (url) => {
      console.log("Recieve props :",url)
    }

    const validateThumbnailImageUrl = (imageUrl) => {
  const baseDomain = process.env.VSF_MAGENTO_BASE_URL; // Replace with your actual domain
  
  if (!imageUrl) return ''; // Handle null/undefined imageUrl
  
  // Extract domain from imageUrl if it exists
  const domainRegex = /^(https?:\/\/[^/]+)(.*)$/; 
  const match = imageUrl.match(domainRegex);

  if (match) {
    const [, domain, path] = match;
    // If the domain doesn't match the base domain, replace it with the correct one
    if (domain !== baseDomain) {
      return `${baseDomain}${path}`;
    }
    return imageUrl; // Domain is already correct
  }

  // If no domain is present in the URL, prepend the base domain
  return `${baseDomain}${imageUrl.startsWith('/') ? imageUrl : `/${imageUrl}`}`;
};

    // @ts-ignore
    return {
      banners,
      callToAction,
      hero,
      page,
      iconsInfo,
      categoryCard,
      worldbrandslider,
      navigateToLink,
      config
    };
  },
  head() {
    return getMetaInfo(this.page);
  },
});
