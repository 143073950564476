
























































import {
  defineComponent,
  ref,
  onMounted,
  useFetch,
  computed,
  useContext,
  watch,
} from "@nuxtjs/composition-api";
import {  useFacet, useUiHelpers } from "~/composables";
import VueSlickCarousel from "vue-slick-carousel";
import ProductCard from "../../components/Cards/ProductCard.vue";
import Loader from "../Loader.vue";

export default defineComponent({
  name: "LatestProductSlider",
  components: {
    VueSlickCarousel,
    ProductCard,
    Loader
  },
  setup() {
    const { result, search,loading } = useFacet();
    const uiHelpers = useUiHelpers();
    const {app} = useContext();
    const carousel = ref(null);

    function onInitCarousel() {
      carousel?.value?.play();
      showNext();
    }

    function showNext() {
      if(carousel.value){

carousel.value.next();
}
    }
    const showPrevious = () => {
      carousel.value.prev();
    };
      



    onMounted(async () => {
      await search({ ...uiHelpers.getFacetsFromURL(), category_uid: 'NDM=',itemsPerPage:100 });
    });

    watch((carousel), () => {
      if(carousel.value){
        showNext();
      }
    })


    const sliderSettings = {
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
      {
        breakpoint: 1545,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,

        }
      },
        {
        breakpoint: 1370,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,

        },
       
      },
      {
        breakpoint: 1156,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,

        },
       
      },
      {
        breakpoint: 812,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

        }
       
      },
      {
        breakpoint: 612,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      }
    ]
    };

    return {
      onInitCarousel,
      carousel,
      showNext,
      showPrevious,
      sliderSettings,
      result,
      app,
      loading:computed(() => loading.value),
    };
  },
});
