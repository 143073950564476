import { render, staticRenderFns } from "./MainSlider.vue?vue&type=template&id=2ab91b12&scoped=true&"
import script from "./MainSlider.vue?vue&type=script&lang=js&"
export * from "./MainSlider.vue?vue&type=script&lang=js&"
import style0 from "./MainSlider.vue?vue&type=style&index=0&id=2ab91b12&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ab91b12",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainSliderCard: require('/var/www/domains/bedquarter.com/public_html/components/MainSlider/MainSliderCard.vue').default,Loader: require('/var/www/domains/bedquarter.com/public_html/components/Loader.vue').default})
